import { HttpClient } from '@angular/common/http';
import { ComponentStore } from '@ngrx/component-store';
import { Review } from 'libs/interfaces/src/lib/review';
import { catchError, EMPTY, Observable, switchMap, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { Injectable } from '@angular/core';
import { environment } from 'apps/admin/src/environments/environment';

interface ReviewState {
    reviews: Review[];
    notVerified: boolean;
    search: string;
    edit: Review | null;
}

@Injectable()
export class ReviewStore extends ComponentStore<ReviewState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
    ) {
        super({
            reviews: [],
            notVerified: true,
            search: '',
            edit: null,
        });
    }

    readonly getNotVerified = this.select((state) => state.notVerified);
    readonly getReviews = this.select((state) =>
        state.reviews.filter((review) => {
            const search = state.search && state.search?.trim() !== '' ? state.search.toLowerCase() : null;

            if (search) {
                const sku = review.product?.sku.toLowerCase().search(search) !== -1;
                const customer = `${review.customer?.firstname} ${review.customer?.lastname}`.toLowerCase().search(search) !== -1;
                const name = review.product?.name.toLowerCase().search(search) !== -1;

                return sku || customer || name;
            } else {
                return state.notVerified ? review.verified === false : true;
            }
        }),
    );
    readonly getGroupedReviews = this.select(this.getReviews, (reviews) =>
        reviews.reduce((agg, review) => ({ ...agg, [review.product_id]: [...(agg[review.product_id] ?? []), review] }), {}),
    );
    readonly getEdit = this.select((state) => state.edit);

    readonly getReviewsEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Review[]>('/api/review').pipe(
                    tap((reviews) => this.patchState({ reviews })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly changeReviewEffect = this.effect((content$: Observable<string>) =>
        content$.pipe(
            switchMap((content) =>
                this.http.patch<Review>(`/api/review/${this.get().edit.id}`, { content }).pipe(
                    tap((review) => {
                        const reviews = [...this.get().reviews];
                        const reviewIndex = reviews.findIndex((r) => r.id === review.id);
                        reviews.splice(reviewIndex, 1, review);
                        this.patchState({ reviews, edit: null });
                        this.toastr.success('Review-ul a fost modificat cu succcess!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly approveReviewEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.patch<Review>(`/api/review/${id}/approve`, {}).pipe(
                    tap((review) => {
                        const reviews = [...this.get().reviews];
                        const reviewIndex = reviews.findIndex((r) => r.id === review.id);
                        reviews.splice(reviewIndex, 1, review);
                        this.patchState({ reviews });
                        this.toastr.success('Review-ul a fost aprobat cu succcess!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly deleteReviewEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.delete(`/api/review/${id}`).pipe(
                    tap((review) => {
                        const reviews = [...this.get().reviews];
                        const reviewIndex = reviews.findIndex((r) => r.id === id);
                        reviews.splice(reviewIndex, 1);
                        this.patchState({ reviews });
                        this.toastr.success('Review-ul a fost sters cu succcess!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly openProductEffect = this.effect((productId$: Observable<string>) =>
        productId$.pipe(
            tap((productId) => {
                const reviewWithProduct = this.get().reviews.find((review) => review.product.id === productId);

                if (reviewWithProduct) {
                    window.open(`${environment.APP_URL}/${reviewWithProduct.product.slug}.html`, '_blank').focus();
                }
            }),
        ),
    );
}
